import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { Heading01 } from "../components/Heading01";
import { Breadcrumb } from "../components/Breadcrumb";
import { CaseItem } from "../components/CaseItem";
import { Heading03 } from "../components/Heading03";
import { Slider01 } from "../components/Slider01";
import { Button01 } from "../components/Button01";
import { Card02 } from "../components/Card02";
import { WpImage } from "../components/WpImage";

// 画像のインポート
import bgimgPc0 from "../images/h1/case/h1SilentCassCorner.png";
import bgimgSp0 from "../images/h1/case/h1SilentCassCorner_sp.png";
import bgimgPc1 from "../images/h1/case/h1CassCorner.png";
import bgimgSp1 from "../images/h1/case/h1CassCorner_sp.png";
import bgimgPc2 from "../images/h1/case/h1SafetySlope.png";
import bgimgSp2 from "../images/h1/case/h1SafetySlope_sp.png";
import bgimgPc3 from "../images/h1/case/h1HighStepCorner.png";
import bgimgSp3 from "../images/h1/case/h1HighStepCorner_sp.png";
import bgimgPc4 from "../images/h1/case/h1AluminumSlope.png";
import bgimgSp4 from "../images/h1/case/h1AluminumSlope_sp.png";
import bgimgPc5 from "../images/h1/case/h1FreeSlope.png";
import bgimgSp5 from "../images/h1/case/h1FreeSlope_sp.png";
import bgimgPc6 from "../images/h1/case/h1CarStopper.png";
import bgimgSp6 from "../images/h1/case/h1CarStopper_sp.png";
import bgimgPc7 from "../images/h1/case/h1LightStep.png";
import bgimgSp7 from "../images/h1/case/h1LightStep_sp.png";
import bgimgPc8 from "../images/h1/case/h1CyclePosition.png";
import bgimgSp8 from "../images/h1/case/h1CyclePosition_sp.png";
import TextLoop from "../components/TextLoop";

const PostCase: React.FC<PageProps<GatsbyTypes.PostCaseQuery>> = ({ data }) => {
  const caseItems = Object.values(data.wpCase?.caseAcf?.case || []);
  const others = Object.values(data.wpCase?.caseAcf?.others || []);
  const allCase = Object.values(data.allWpCase.edges || []);
  // その他の施工事例表示チェック、0なら非表示に
  const isShowOthers = others
    .map((other) => other?.text && other.img)
    .filter((val) => val !== null).length;

  // h1の画像設定
  const bgimg = [bgimgPc0, bgimgSp0]; //デフォルトでサイレントキャスコーナーの画像
  switch (data.wpCase?.title) {
    case "キャスコーナーＭ級・Ｈ級":
      bgimg[0] = bgimgPc1;
      bgimg[1] = bgimgSp1;
      break;
    case "セフティ・スロープ":
      bgimg[0] = bgimgPc2;
      bgimg[1] = bgimgSp2;
      break;
    case "ハイステップ・コーナー":
      bgimg[0] = bgimgPc3;
      bgimg[1] = bgimgSp3;
      break;
    case "アルミスロープ":
      bgimg[0] = bgimgPc4;
      bgimg[1] = bgimgSp4;
      break;
    case "フリースロープ":
      bgimg[0] = bgimgPc5;
      bgimg[1] = bgimgSp5;
      break;
    case "カーストッパー":
      bgimg[0] = bgimgPc6;
      bgimg[1] = bgimgSp6;
      break;
    case "ライトステップ":
      bgimg[0] = bgimgPc7;
      bgimg[1] = bgimgSp7;
      break;
    case "サイクル・ポジション":
      bgimg[0] = bgimgPc8;
      bgimg[1] = bgimgSp8;
      break;
  }

  return (
    <Layout title="施工事例" description="ミスギの施工事例です。">
      <article className="">
        <Heading01
          label={data.wpCase?.title || ``}
          smallLabel={`施工事例`}
          className={`text-[20px] pc:text-[32px]`}
          bgimg={bgimg}
        />
        <Breadcrumb
          labels={[
            { url: "/case", text: "施工事例" },
            {
              url: "",
              text: data.wpCase?.title ? data.wpCase?.title : "",
            },
          ]}
        />

        {/* 施工事例メイン */}
        <div className="pt-8 pb-[72px] pc:pt-28 pc:pb-32">
          <ul>
            {caseItems.map(
              (item, index) =>
                item?.text && (
                  <CaseItem
                    key={index}
                    number={index + 1}
                    text={item.text}
                    img={item.img}
                  />
                )
            )}
          </ul>
        </div>

        {/* その他の施工事例 スライダー */}
        {isShowOthers !== 0 && (
          <div className="pb-16 pc:pb-20">
            <Heading03
              label="その他の施工事例"
              bigLabel="OTHER"
              isCenter={true}
            />
            <div className="mx-auto mt-14 max-w-[500px] tb:max-w-[800px]">
              <Slider01 slidePerViews={[1.4, 2, 3]}>
                {others.map(
                  (other, index) =>
                    other?.text && (
                      <li className="flex flex-col items-center" key={index}>
                        <WpImage
                          image={other?.img}
                          className="h-[200px] w-[200px] rounded-2xl"
                        />
                        <p className="mt-4 text-center text-base">
                          {other?.text}
                        </p>
                      </li>
                    )
                )}
              </Slider01>
            </div>
          </div>
        )}

        {/* 他製品の施工事例 */}
        <div className="relative bg-bggrey pt-10 pb-16 pc:py-[72px]">
          <div className="l-container-sm">
            <h2 className="text-center text-xl font-bold pc:text-left pc:text-3xl">
              他製品の施工事例
            </h2>
            <div className="mt-10 pc:mt-12">
              <ul className="grid grid-cols-1 gap-y-8 tb:grid-cols-2 tb:gap-x-5 pc:grid-cols-3">
                {allCase.map(
                  (nodes, index) =>
                    nodes.node.title && (
                      <Card02
                        key={index}
                        heading={nodes.node.title}
                        link={`/case/${nodes.node.slug}`}
                        description={nodes.node.caseAcf?.catch}
                        img={nodes.node.featuredImage?.node}
                      />
                    )
                )}
              </ul>
            </div>
            <div className="mt-10 text-center">
              <Button01
                label="施工事例をもっと見る"
                link="/case"
                isWhite={true}
                isPageTransition={true}
              />
            </div>
          </div>
          {/* 文字ループアニメーション */}
          <div className="absolute top-0 left-0 hidden h-full w-32 items-start pc:flex">
            <TextLoop
              text="CASE CASE CASE"
              className="w-[730px] flex-shrink-0 origin-top-left translate-x-[120px] rotate-90 tracking-[0.048em] text-white pc:text-[104px]"
              design={2}
            />
          </div>
          <div className="absolute top-0 right-0 hidden h-full w-32 items-end pc:flex">
            <TextLoop
              text="CASE CASE CASE"
              className="w-[730px] flex-shrink-0 origin-bottom-left translate-x-[120px] -rotate-90 tracking-[0.048em] text-white pc:text-[104px]"
              design={2}
            />
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default PostCase;

export const query = graphql`
  query PostCase($id: String!) {
    allWpCase(limit: 3, filter: { id: { ne: $id } }) {
      edges {
        node {
          title
          slug
          caseAcf {
            catch
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    wpCase(id: { eq: $id }) {
      title
      caseAcf {
        catch
        others {
          other01 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other02 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other03 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other04 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other05 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other06 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other07 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          other08 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        case {
          case01 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          case02 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          case03 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          case04 {
            text
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
