import React from "react";

import { Props } from "../../types/CaseItem";
import { WpImage } from "../WpImage";

import * as style from "./CaseItem.module.scss";

/**
 * パンクズ
 */
export const Presenter: React.VFC<Props> = ({ number, text, img }) => {
  return (
    <li className={`${style.bg}`}>
      <div className="l-container-sm">
        <h2
          style={{
            textShadow:
              "1px 1px 0 #2B823F,-1px -1px 0 #2B823F,1px -1px 0 #2B823F,-1px 1px 0 #2B823F",
          }}
          className="bg-clip-text text-[64px] font-bold text-white pc:text-[96px]"
        >
          CASE 0{number}
        </h2>
        <div className="flex flex-col items-center justify-between pc:-mt-20 pc:flex-row">
          {text && (
            <p
              className="text-base font-bold pc:text-xl"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
          <div className="mt-6 flex-shrink-0 pc:ml-20 pc:mt-0">
            <WpImage
              image={img}
              className="h-[228px] w-[335px] overflow-hidden rounded-2xl pc:h-[280px] pc:w-[340px]"
            />
          </div>
        </div>
      </div>
    </li>
  );
};
